<template>
  <a-spin :spinning="loading">
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="ICCID/SIMID">
            {{ data.iccid }}
          </a-descriptions-item>
          <a-descriptions-item label="MSISDN">
            {{ data.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="IMSI">
            {{ data.imsi }}
          </a-descriptions-item>
          <a-descriptions-item label="短号">
            {{ data.short_number }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商种类">
            {{ data.carrier_type }}
          </a-descriptions-item>
          <a-descriptions-item label="卡品">
            {{ data.card_type_and_shape }}
          </a-descriptions-item>
          <a-descriptions-item label="原生贴片">
            {{ data.is_native_smt }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-7"
        >
          <a-descriptions-item label="分销商">
            {{ data.top_agent_name }}
          </a-descriptions-item>
          <a-descriptions-item label="企业用户">
            {{ data.agency_name }}
          </a-descriptions-item>
          <a-descriptions-item label="生命周期">
            {{ data.life_cycle }}
          </a-descriptions-item>
          <a-descriptions-item label="联网状态">
            {{ data.net_status }}
          </a-descriptions-item>
          <a-descriptions-item label="单卡限额">
            {{ data.limit_usage_value }}
          </a-descriptions-item>
          <a-descriptions-item label="测试流量">
            {{ data.test_traffic_usage }} / {{ data.test_traffic }}
          </a-descriptions-item>
          <a-descriptions-item label="测试期截止时间">
            {{ data.test_period_end_date }}
          </a-descriptions-item>
          <a-descriptions-item label="是否可续期订购">
            {{ data.renewable }}
          </a-descriptions-item>
          <a-descriptions-item label="VPDN分组ID">
            {{ data.vpdn_id }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-8"
        >
          <a-descriptions-item label="入库时间">
            {{ data.imported_at }}
          </a-descriptions-item>
          <a-descriptions-item label="划拨时间">
            {{ data.allocated_at }}
          </a-descriptions-item>
          <a-descriptions-item label="沉默期截止时间">
            {{ data.silent_period_end_date }}
          </a-descriptions-item>
          <a-descriptions-item label="激活时间">
            {{ data.activated_at }}
          </a-descriptions-item>
          <a-descriptions-item label="服务周期结束时间">
            {{ data.service_end_time }}
          </a-descriptions-item>
          <a-descriptions-item label="预计销卡时间">
            {{ data.expect_cancel_time }}
          </a-descriptions-item>
          <a-descriptions-item label="数据更新时间">
            {{ data.data_updated_at }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { findSimCardBaseInfo } from '@/api/sim_card'

export default {
  name: 'ShowSimCardBaseInfo',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findSimCardBaseInfo(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
